
import { Component, Vue } from 'vue-property-decorator'
import { Link } from './link'
import axios from 'axios'
import PublicProjectListing from './components/Project/PublicProjectListingModel'

@Component({
  components: {

  }
})
export default class App extends Vue {
  links: Link[] = [
    new Link('Home', '/'),
    new Link('Home', '/', true),
    new Link('Projects', '/projects'),
    new Link('Projects', '/projects', true),
    new Link('About us', '/about'),
    new Link('About us', '/about', true),
    new Link('Contact us', '/contactUs')
  ]

  mounted (): void {
    this.loadProjectSrcs()
  }

  async loadProjectSrcs (): Promise<void> {
    try {
      // @ts-ignore
      this.$root.publicProjectImageSrcs.splice(0, this.$root.publicProjectImageSrcs.length)
      // @ts-ignore
      const url = `${this.$root.baseUri}PublicProjects/GetProjectsToDisplayLinks`
      const response = await axios.get(url,
        {
          params: {
            tenantId: 2
          }
        })
      if (Array.isArray(response?.data?.result)) {
        response.data.result.forEach((pp: PublicProjectListing) => {
          // @ts-ignore
          this.$root.publicProjectImageSrcs.push(pp)
        })
      } else {
        throw new Error('Expected an array in the result')
      }
      // @ts-ignore
      this.$root.publicProjectImageSrcs.sort((a: PublicProjectListing, b: PublicProjectListing) => {
        if (a.order !== null && b.order !== null) {
          return a.order - b.order
        }
        if (a.order !== null && b.order === null) {
          return -1
        }
        if (a.order === null && b.order !== null) {
          return 1
        }
        if (a.nameOverride && b.nameOverride) {
          if (a.nameOverride.toLowerCase() < b.nameOverride.toLowerCase()) {
            return -1
          }
          if (a.nameOverride.toLowerCase() > b.nameOverride.toLowerCase()) {
            return 1
          }
        }
        if (a.nameOverride && !b.nameOverride) {
          if (a.nameOverride.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }
          if (a.nameOverride.toLowerCase() > b.name.toLowerCase()) {
            return 1
          }
        }
        if (!a.nameOverride && b.nameOverride) {
          if (a.name.toLowerCase() < b.nameOverride.toLowerCase()) {
            return -1
          }
          if (a.name.toLowerCase() > b.nameOverride.toLowerCase()) {
            return 1
          }
        }
        if (!a.nameOverride && !b.nameOverride) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          }
        }
        return 0
      })
      // @ts-ignore
      const topTen: any[] = this.$root.publicProjectImageSrcs.filter((x: any) => x?.order != null && x?.order > 0)
      // @ts-ignore
      this.$root.topTenPublicProjects.splice(0)
      // @ts-ignore
      topTen.forEach(x => this.$root.topTenPublicProjects.push(x))
    } catch (e) {
      console.error(e)
    }
  }
}
