export class Link {
  name: string
  path: string
  separator: boolean

  constructor (name: string, path: string, separator = false) {
    this.name = name
    this.path = path
    this.separator = separator
  }
}
