
import { Component, Prop, Vue } from 'vue-property-decorator'
import PublicProjectListing from './PublicProjectListingModel'

@Component
export default class Project extends Vue {
  @Prop() private publicProjectListing!: PublicProjectListing
  @Prop() private baseUri!: string

  public aspect = '16:9'
  public fullImagePath?: string = ''
  public name = ''
  public show = false

  mounted (): void {
    // path
    const fixedPath = this.publicProjectListing.imagePath.replaceAll('\\\\', '/')
    this.fullImagePath = `${this.baseUri}${fixedPath}`
    // name
    this.name = this.publicProjectListing.nameOverride ?? this.publicProjectListing.name
  }
}
