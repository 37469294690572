
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {

  }
})
export default class Home extends Vue {
  slide = 0

  sliding: boolean|null = null

  onSlideStart (): void {
    this.sliding = true
  }

  onSlideEnd (): void {
    this.sliding = false
  }
}
