
import { Component, Vue } from 'vue-property-decorator'
import Project from '../components/Project/Project.vue'

@Component({
  components: {
    Project
  }
})
export default class Projects extends Vue {

}
