import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

new Vue({
  data: {
    publicProjectImageSrcs: [],
    topTenPublicProjects: [],
    baseUri: process.env.VUE_APP_EXTERNAL_SVR_URL
  },
  router,
  render: h => h(App)
}).$mount('#app')
